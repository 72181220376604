var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-container',{attrs:{"fluid":""}},[_c('v-snackbar',{attrs:{"color":_vm.snackbarColor,"right":_vm.x === 'right',"timeout":_vm.timeout,"top":_vm.y === 'top'},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(_vm._s(_vm.snackbarMessage))]),_c('v-row',[_c('loading',{attrs:{"active":_vm.isLoading,"loader":_vm.loader}})],1),_c('v-row',[_c('v-col',{attrs:{"xs":"12","sm":"6","md":"4"}},[_c('v-autocomplete',{attrs:{"items":_vm.serviceStatusList,"label":"Search By Job Status","item-text":"key","item-value":"value","outlined":"","dense":""},on:{"change":function($event){return _vm.searchOnServer(_vm.jobStatus)}},model:{value:(_vm.jobStatus),callback:function ($$v) {_vm.jobStatus=$$v},expression:"jobStatus"}})],1),_c('v-spacer'),_c('v-col',{staticClass:"mt-3",attrs:{"xs":"12","sm":"2","md":"1"}},[_c('v-btn',{attrs:{"dark":"","block":"","color":"rgb(231,87,83)","to":{
          name: 'Dashboard',
        }}},[_vm._v("Back")])],1)],1),_c('v-row',[_c('v-col',{attrs:{"xs":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalItems,"footer-props":{
          'items-per-page-options': [10, 20, 30]
        },"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.indexNo",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s((_vm.options.page * 10) - (10 - (_vm.items.indexOf(item) + 1) )))])]}},{key:"item.startDateTime",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.startDateTime ? _vm.moment(item.startDateTime).format("MMMM DD, YYYY, HH:mm") : '......................')+" ")]}},{key:"item.finishDateTime",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.finishDateTime ? _vm.moment(item.finishDateTime).format("MMMM DD, YYYY, HH:mm") : '......................')+" ")]}},{key:"item.runTime",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.runTime ? _vm.moment(item.runTime).format("MMMM DD, YYYY, HH:mm") : '......................')+" ")]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }